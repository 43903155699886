import useSWR from 'swr';
import { strapiUrl } from '../../config/env';

const fetcher = async (
  input: RequestInfo,
  init: RequestInit,
  ...args: any[]
) => {
  const res = await fetch(input, init);
  return res.json();
};

const FetchWithSWR = (path: string) => {
  const { data, error } = useSWR(`${strapiUrl}${path}`, fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const fetchProperties = () => {
  return FetchWithSWR('/ejendom-order');
}

export const fetchProperty = (propertyNumber: number) => {
  return FetchWithSWR(`/ejendoms/${propertyNumber}`);
}

export const fetchVacantProperties = () => {
  return FetchWithSWR('/ledigelejemaal');
}
