import {
  Box,
  Flex,
  Heading,
  Icon,
  Image,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import { BiArea, BiHomeAlt } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { fetchProperty } from '../components/data/SWR';
import { strapiUrl } from '../config/env';
import { ColoredLine } from './Ejendomme';

interface PropertyProps {
  Adresse: string;
  Antal_Lejemaal: number;
  Beskrivelsse: string;
  Billede: {
    formats: {
      small: { url: string };
      medium: { url: string };
      large: { url: string };
    };
    url: string;
  };
  Ekstra_Billeder: { url: string }[];
  Galleri: { url: string }[];
  Kvadratmeter: string;
}

interface ParamsProps {
  property: string;
}

const Ejendom: FC = () => {
  const params = useParams<ParamsProps>();
  const [loaded0, setLoaded0] = React.useState<boolean>(false);
  const [loaded1, setLoaded1] = React.useState<boolean>(false);
  const [loaded2, setLoaded2] = React.useState<boolean>(false);
  const data: PropertyProps = fetchProperty(Number(params.property)).data;
  console.log(data);

  return (
    <>
      {data && (
        <>
          <Skeleton isLoaded={loaded0}>
            <Image
              src={`${strapiUrl}${data && data.Billede.url}`}
              h={'70vw'}
              objectFit={'cover'}
              w={'100%'}
              onLoad={() => {
                setLoaded0(true);
              }}
              borderRadius={[0, '8px']}
            />
          </Skeleton>

          <Flex mr={'5vw'} ml={'5vw'} direction={'column'} mt={'20px'}>
            <Heading
              m={'auto'}
              mb={'20px'}
              fontWeight={'normal'}
              fontStyle={'normal'}
              fontSize={['8vw', '4vw']}
            >
              {data && data.Adresse}
            </Heading>
            <ColoredLine />

            <Flex
              mt={'50px'}
              flexDirection={{ base: 'column', md: 'row' }}
              gridRowGap={'2vw'}
            >
              <Box>
                <Text fontSize={['4vw', '1.1vw']}>{data?.Beskrivelsse}</Text>
              </Box>

              <Box flex={'0 0 3%'} />
              <Box flex={'0 0 48.5%'}>
                <Flex align={'center'}>
                  <Icon
                    w={['14vw', '1.8vw']}
                    h={['14vw', '1.8vw']}
                    mr={'0.3vw'}
                    as={BiHomeAlt}
                  />
                  <Text fontSize={['6vw', '1.4vw']}>
                    {data?.Antal_Lejemaal} lejemål
                  </Text>
                </Flex>
                {data?.Kvadratmeter && (
                  <Flex mt={'0.8vw'} align={'center'}>
                    <Icon
                      w={['14vw', '1.8vw']}
                      h={['14vw', '1.8vw']}
                      mr={'0.3vw'}
                      as={BiArea}
                    />
                    <Text fontSize={['6vw', '1.4vw']}>
                      {data.Kvadratmeter} m&#xb2;
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
            <Flex
              mt={'50px'}
              flexDirection={{ base: 'column', md: 'row' }}
              gridRowGap={'2vw'}
            >
              {data?.Ekstra_Billeder[0] && (
                <Box>
                  <Skeleton isLoaded={loaded1}>
                    <Image
                      src={`${strapiUrl}${data?.Ekstra_Billeder[0]?.url}`}
                      onLoad={() => {
                        setLoaded1(true);
                      }}
                      borderRadius={'8px'}
                      display="block"
                      h="auto"
                      w="100%"
                    />
                  </Skeleton>
                </Box>
              )}
              {data?.Ekstra_Billeder[1] && (
                <>
                  <Box flex={'0 0 3%'} />
                  <Box>
                    <Skeleton isLoaded={loaded2}>
                      <Image
                        src={`${strapiUrl}${data?.Ekstra_Billeder[1]?.url}`}
                        onLoad={() => {
                          setLoaded2(true);
                        }}
                        borderRadius={'8px'}
                        display="block"
                        h="auto"
                        w="100%"
                      />
                    </Skeleton>
                  </Box>
                </>
              )}
            </Flex>

            <SimpleGrid minChildWidth={['50vw', '30vw']} mt="50px" spacing={30}>
              {data &&
                data?.Galleri.map((image: { url: any }, x: any) => {
                  return (
                    <Image
                      key={image.url}
                      src={`${strapiUrl}${image?.url}`}
                      borderRadius={'8px'}
                      display="block"
                      objectFit={'cover'}
                      h={['100%', '28vw']}
                      w="100%"
                    />
                  );
                })}
            </SimpleGrid>
          </Flex>
        </>
      )}
    </>
  );
};

export default Ejendom;
