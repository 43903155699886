import {
  Button,
  Flex,
  Heading,
  Icon,
  Link as ChakraLink,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  SimpleGrid,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import {
  BiHomeSmile,
  BiKey,
  BiLogIn,
  BiLogOut,
  BiPaintRoll,
  BiTaskX,
  BiTrash,
  BiTv,
  BiWind,
} from 'react-icons/bi';
import { Link } from 'react-router-dom';
import '../App.scss';
import { ColoredLine } from './Ejendomme';

const LejerInfo: FC = () => {
  const {
    isOpen: isOpenKlima,
    onOpen: onOpenKlima,
    onClose: onCloseKlima,
  } = useDisclosure();
  const {
    isOpen: isOpenSkrald,
    onOpen: onOpenSkrald,
    onClose: onCloseSkrald,
  } = useDisclosure();
  const {
    isOpen: isOpenFraflytning,
    onOpen: onOpenFraflytning,
    onClose: onCloseFraflytning,
  } = useDisclosure();
  const {
    isOpen: isOpenOpsigelse,
    onOpen: onOpenOpsigelse,
    onClose: onCloseOpsigelse,
  } = useDisclosure();
  const {
    isOpen: isOpenKeys,
    onOpen: onOpenKeys,
    onClose: onCloseKeys,
  } = useDisclosure();
  const {
    isOpen: isOpenIndflytning,
    onOpen: onOpenIndflytning,
    onClose: onCloseIndflytning,
  } = useDisclosure();
  const {
    isOpen: isOpenHusorden,
    onOpen: onOpenHusorden,
    onClose: onCloseHusorden,
  } = useDisclosure();
  const {
    isOpen: isOpenVedligeholdelse,
    onOpen: onOpenVedligeholdelse,
    onClose: onCloseVedligeholdelse,
  } = useDisclosure();
  const {
    isOpen: isOpenAntenne,
    onOpen: onOpenAntenne,
    onClose: onCloseAntenne,
  } = useDisclosure();
  const B = (props: any) => (
    <Text style={{ fontWeight: 'bold' }}>{props.children}</Text>
  );

  return (
    <>
      <Flex pr={'70px'} pl={'70px'} w={'100%'} direction={'column'} mt={'20px'}>
        <Heading
          m={'auto'}
          mb={'20px'}
          fontWeight={'normal'}
          fontStyle={'normal'}
          fontSize={['8vw', '4vw']}
        >
          Lejer Information
        </Heading>
        <ColoredLine />
      </Flex>
      <SimpleGrid
        w={'90%'}
        minChildWidth={['100%', '23vw']}
        mt="50px"
        spacing={30}
      >
        <Flex
          cursor={'pointer'}
          onClick={onOpenKlima}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading fontSize={['9vw', '2.3vw']} mb={'1vw'} fontWeight={'medium'}>
            Indeklima
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiWind} />
          <Text fontSize={['4vw', '1.2vw']}>
            10 gode råd til et godt indeklima
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenSkrald}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            mb={'1vw'}
            w={'80%'}
            fontSize={['9vw', '2.3vw']}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Affald, pap og storskrald
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiTrash} />
          <Text fontSize={['4vw', '1.2vw']}>
            Sådan skiller du dig af med dit affald
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenFraflytning}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            fontSize={['9vw', '2.3vw']}
            mb={'1vw'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Fraflytning
          </Heading>
          <Icon
            mb={'1vw'}
            w={['30%', '20%']}
            h={['30%', '20%']}
            as={BiLogOut}
          />
          <Text fontSize={['4vw', '1.2vw']}>Flyttesyn & rengøring</Text>
        </Flex>

        <Flex
          cursor={'pointer'}
          onClick={onOpenOpsigelse}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            fontSize={['9vw', '2.3vw']}
            mb={'1vw'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Opsigelse af lejemål
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiTaskX} />
          <Text fontSize={['4vw', '1.2vw']}>Frister</Text>
        </Flex>

        <Flex
          cursor={'pointer'}
          onClick={onOpenKeys}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            fontSize={['9vw', '2.3vw']}
            mb={'1vw'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Nøgler
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiKey} />
          <Text fontSize={['4vw', '1.2vw']}>
            Udlevering og aflevering af nøgler
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenIndflytning}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            fontSize={['9vw', '2.3vw']}
            mb={'1vw'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Indflytning
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiLogIn} />
          <Text fontSize={['4vw', '1.2vw']}>
            Vand/varme, el og fejl & mangler
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenHusorden}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            fontSize={['9vw', '2.3vw']}
            mb={'1vw'}
            fontWeight={'medium'}
            textAlign={'center'}
          >
            Husorden
          </Heading>
          <Icon
            mb={'1vw'}
            w={['30%', '20%']}
            h={['30%', '20%']}
            as={BiHomeSmile}
          />
          <Text textAlign={'center'} fontSize={['4vw', '1.2vw']}>
            Gældende husorden for alle Toft Ejendommes boliger
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenVedligeholdelse}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            mb={'1vw'}
            mx={'20px'}
            fontWeight={'medium'}
            textAlign={'center'}
            fontSize={['9vw', '2.3vw']}
          >
            Generel Vedligeholdelse
          </Heading>
          <Icon
            mb={'1vw'}
            w={['30%', '20%']}
            h={['30%', '20%']}
            as={BiPaintRoll}
          />
          <Text fontSize={['4vw', '1.2vw']}>
            Lejers forpligtelser til vedligeholdse af lejemålet
          </Text>
        </Flex>
        <Flex
          cursor={'pointer'}
          onClick={onOpenAntenne}
          flexDir="column"
          justify={'center'}
          align={'center'}
          bg={'#D1CABD'}
          w={'100%'}
          h={['60vw', '15vw']}
          borderRadius={'8px'}
        >
          <Heading
            mb={'1vw'}
            mx={'20px'}
            fontWeight={'medium'}
            textAlign={'center'}
            fontSize={['9vw', '2.3vw']}
          >
            Antenne Forhold
          </Heading>
          <Icon mb={'1vw'} w={['30%', '20%']} h={['30%', '20%']} as={BiTv} />
          <Text fontSize={['4vw', '1.2vw']} display={'flex'} 
            textAlign={'center'}>
            Information om modtagning af TV <br /> i vores lejligheder
          </Text>
        </Flex>
      </SimpleGrid>
      <Modal isOpen={isOpenKlima} onClose={onCloseKlima}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              10 gode råd om opvarmning og udluftning
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrderedList spacing={5}>
              <ListItem>
                Hold normalt alt lukket i fyringssæsonen og undgå træk. Når du
                ønsker at lufte ud, gøres det bedst ved at lave kortvarige
                kraftige udluftninger af 5-10 minutters varighed. Skru ned for
                dine radiatorer, mens du lufter ud. Dug indvendig på
                termovinduer er tegn på, at der skal luftes mere ud.
              </ListItem>
              <ListItem>
                {' '}
                Undgå en høj luftfugtighed i lejligheden. En fugtmættet luft vil
                føles koldere end en tør luft ved samme temperatur. Og fugt kan
                skade boligen. Fugtig luft er dyrere at opvarme end tør luft.
              </ListItem>
              <ListItem>
                {' '}
                Tilstop ikke ventilationsåbninger i badeværelse og køkken.{' '}
              </ListItem>
              <ListItem>
                Lad være med at tørre tøj på radiatorerne. Det giver for høj
                fugt i din bolig med risiko for fugtskader.{' '}
              </ListItem>
              <ListItem>
                {' '}
                Har du flere radiatorer i samme rum, skal du stille alle på
                samme temperatur.{' '}
              </ListItem>
              <ListItem>
                {' '}
                Hold dørene lukket, hvis du ønsker lavere temperaturer i nogle
                rum. For eksempel i soveværelse og køkken.{' '}
              </ListItem>
              <ListItem>
                Det koster dig minimum 3-5 % mere i energiforbrug, hvis du hæver
                varmen med 1 grad.{' '}
              </ListItem>
              <ListItem>
                {' '}
                Undgå at tildække radiatorer og termostatventiler med gardiner
                og møbler. En varmemåler på en tildækket radiator vil registrere
                en meget høj varmeafgivelse uden at den kommer rummet til gode.{' '}
              </ListItem>
              <ListItem>
                {' '}
                Stil ikke møbler helt op af kolde ydervægge, da dette kan give
                mug på vægge.{' '}
              </ListItem>
              <ListItem>
                {' '}
                Tager du væk fra din lejlighed i længere tid, kan du skrue lidt
                ned for varmen. Du må dog ikke lukke helt, da du er forpligtet
                til at vedligeholde din lejlighed ved at opvarme den, så der
                ikke sker fugt- eller frostskader{' '}
              </ListItem>
            </OrderedList>
          </ModalBody>

          <ModalFooter>
            <Link to="indeklima.pdf" target="_blank" download>
              <Button>Download som PDF</Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenSkrald} onClose={onCloseSkrald}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Affald, pap og storskrald
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Der henstilles til, at de opstillede skraldespande/containere ikke
            anvendes til bortskaffelse af pap og andet storskrald.
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenFraflytning} onClose={onCloseFraflytning}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Fraflytning
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight={'bold'}>Elaflæsning</Text>
            I forbindelse med fraflytning aflæser den fraflyttende lejer selv
            elmåler. Aflæsningen indtelefoneres til Energi Midt på telefon 70 15
            15 60 Elregningen sendes direkte til lejeren, da der ikke betales
            for dette til udlejer via huslejen.
            <br /> <br />
            <Text fontWeight={'bold'}>Flyttesyn</Text>
            Ifølge lejekontrakten udarbejder lejer og udlejer sammen en
            fraflytningsrapport. <br />
            De nærmere vilkår vedr. fraflytning findes i lejekontrakten. <br />{' '}
            <Flex whiteSpace={'pre-wrap'} flexDir="row" flexWrap={'wrap'}>
              Lejer skal aftale flyttesyn med udlejer senest <B>14 dage</B> før
              fraflytning.{' '}
            </Flex>
            <br />
            Flyttesynet finder sted, når lejemålet er fuldstændigt tømt og
            hovedrengjort med nypudsede vinduer ind- og udvendigt, herunder
            udskiftning af emhættefilter samt vask af trappe/r efter
            fraflytning. (uagtet der betales for trappevask). <br />
            <br />
            Samtlige nøgler til lejemålet skal afleveres ved fraflytningssynet.{' '}
            <br />
            <br />
            <B>
              Ved syn opleves desværre ofte følgende mangler omkring lejers
              rengøring:
            </B>{' '}
            <br />
            <UnorderedList spacing={5}>
              <ListItem>Eftertørring af skabslåger efter rengøring.</ListItem>{' '}
              <ListItem>Rengøring af emhætte indvendig.</ListItem>{' '}
              <ListItem>Emhættefilter er ikke udskiftet.</ListItem>
              <ListItem>
                Rengøring af rør, radiator, vindueskarme og stikkontakter.
              </ListItem>
              <ListItem>
                Rengøring og pudsning af vinduer ind- og udvendigt.
              </ListItem>
              <ListItem>Rengøring af trapper efter flytning.</ListItem>
              <ListItem>
                Ovn er ikke renset og rengjort, herunder bageplader og riste.
              </ListItem>
              <ListItem>Komfurets udvendige sider er ikke rengjorte.</ListItem>
              <ListItem>
                Køle/frys: Skuffer, hylder og riste er ikke intakte/rengjorte.
              </ListItem>
              <ListItem>
                Blandingsbatterier, brusehoved, fliser på væg og gulv især i
                badeværelse og især ved afløbsforhold er ikke
                afkalkede/rengjorte.
              </ListItem>
              <ListItem>
                Loftsrosetter til el-udtag er ikke monteret efter nedtagning af
                lamper.
              </ListItem>
              <ListItem>
                Badeværelsesforhæng (hvis der er et sådant) er ikke blevet
                vasket/skiftet.
              </ListItem>
              <ListItem>
                Pærer i ovn, køleskab, emhætte og faste lampe virker ikke. –
                Husk at skifte pærer.
              </ListItem>
            </UnorderedList>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenOpsigelse} onClose={onCloseOpsigelse}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Opsigelse af lejemål
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UnorderedList spacing={5}>
              <ListItem flexWrap={'nowrap'}>
                Opsigelsen skal være mailet til Toft Ejendomme på{' '}
                <ChakraLink href={'mailto:info@toftejendomme.dk'} isExternal>
                  info@toftejendomme.dk
                </ChakraLink>{' '}
                senest sidste hverdag i måneden
              </ListItem>

              <ListItem>
                Opsigelsen er først gyldig, når den er underskrevet af samtlige
                lejere i henhold til lejekontrakten
              </ListItem>
              <ListItem>
                Ønskes lejemålet genudlejet før opsigelsesfristens udløb oplyses
                dette i opsigelsen (under &quot;Lejemålet ønskes genudlejet den ...&quot;)
              </ListItem>
            </UnorderedList>
            <br />
            <Text>
              Når du har opsagt dit lejemål, vil du modtage en bekræftelse på
              opsigelsen.
            </Text>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenKeys} onClose={onCloseKeys}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Nøgler
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Nøgler til lejemålet udleveres i forbindelse med indflytning.{' '}
              <br />
              Hvis det ikke er en systemnøgle kan lejer selv lave kopier heraf.{' '}
              <br />
              Er det en systemnøgle, skal Toft Ejendomme fremskaffe ekstra
              nøgler, som betales af lejeren. <br />
              Vi har desværre ikke mulighed for at låse op i tilfælde af tabt
              nøgle, men henviser i stedet til låsesmed. <br /> Når lejemålet
              fraflytte afleveres det samme antal nøgler, som blev udleveret ved
              indflytning samt evt. ekstra kopier.
            </Text>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenIndflytning} onClose={onCloseIndflytning}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Indflytning
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Ved indflytning har skal lejeren, hvis der er fejl eller mangler
              ved lejligheden, inden 14 dage skriftligt udarbejde en liste, der
              skal fremsendes til udlejer. <br />
              Ved indflytning aflæses el og lejer skal herefter selv tage
              kontakt til Energi Midt på 70 15 15 60 og afgive de aflæste tal.{' '}
              <br />
              Udlejer bestiller aflæsning af varmemålere/vandmålere, og lejeren
              vil blive kontaktet direkte vedr. tidspunkt for dette.
            </Text>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenHusorden} onClose={onCloseHusorden}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Husorden
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrderedList spacing={5}>
              <ListItem fontWeight={'bold'}>
                Generelt
                <Text fontWeight={'normal'}>
                  Denne husorden er gældende som vilkår til den indgående
                  lejekontrakt
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Påbud, Ansvar for gæster m.v.
                <Text fontWeight={'normal'}>
                  Lejeren er udover de skrevne regler i HUSORDENEN endvidere
                  forpligtet til at efterkomme og overholde de mundtlige eller
                  skriftlige påbud, der måtte blive meddelt af udlejeren eller
                  dennes stedfortræder, herunder vicevært, hvilket også gælder
                  de personer, som lejeren er ansvarlig for.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Ændring af Husorden
                <Text fontWeight={'normal'}>
                  Reglerne i denne husorden kan efterfølgende løbende ændres
                  eller revideres af udlejeren, såfremt der findes behov herfor.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Vaskerum m.v.
                <Text fontWeight={'normal'}>
                  De i fællesvaskeriet, hvis et sådan findes, opsatte regler for
                  brug og renholdelse udgør også en del af de gældende
                  husordensregler. Tøjtørring o.lign. må KUN ske i tørrerummet
                  eller udendørs og kun på de af udlejer/vicevært anviste
                  steder. Såfremt der ligger tøj i vaskehuset eller hænger tøj i
                  tørrerummet i mere end en uge, bliver det lagt i en sort sæk
                  og sat over i et hjørne af vaskehuset. Er det ikke fjernet
                  herfra efter endnu en uge bliver det smidt ud! Det er forbudt
                  at tørre tøj i lejligheden, da fugten herfra kan forringe
                  eller ødelægge tapet og træværk. Det er forbudt at overlade
                  brugen af fællesvaskeriet til andre personer end ejendommens
                  lejere.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Fællesarealer, pulterrum, m.v.
                <Text fontWeight={'normal'}>
                  Lejeren har kun ret til at disponere over fællesrum og
                  fællesarealer, såvel indendørs som udendørs, efter anvisning
                  og med respekt af de øvrige lejeres tilsvarende brugsret. Det
                  er ikke tilladt at henstille indbo, andre effekter, affald
                  el.lign. på trapper eller øvrige fællesarealer. Lejeren har
                  kun ret til at disponere over 1 pulterrum, såfremt dette
                  udtrykkeligt er anvist af udlejer. Pulterrum skal altid være
                  aflåst og være afmærket med skilt med oplysning om lejers navn
                  eller lejlighed. Det er lejerens ansvar, at skiltene er sat op
                  på pulterrummet. Alt hvad der anbringes i kælderen (cykler,
                  barnevogne, møbler osv.) sker på lejerens egen regning og
                  risiko. Udlejer påtager sig IKKE ansvar på nogen måde for ting
                  anbragt i kælderen. <br /> <br />
                  Det er ikke tilladt at henstille knallerter i ejendommes
                  kælder eller opbevare brandfarlige væsker o.lign. <br />{' '}
                  <br />
                  Barnevogne, legevogne, cykler og lignende må ikke henstilles i
                  opgangen. Sæt tingene i kælderen i stedet. Det er ikke for at
                  genere jer, at vi har indført disse regler, men det er for at
                  der skal se ordentligt ud i jeres opgange og for at forhindre,
                  at der kan sættes ild til tingene med deraf følgende
                  uhyggelige konsekvenser. <br /> <br />
                  Evt. beskadigelser af fællesarealer, henstilling af affald
                  o.lign. samt graffiti vil blive repareret/fjernet for den
                  pågældende lejers regning.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Affald
                <Text fontWeight={'normal'}>
                  Lejers affald må kun henkastes i de hertil anviste beholdere,
                  og dette skal ske i lukkede plastposer og må ikke indeholde
                  skarpe og spidse genstande. Det er forbudt at henstille affald
                  ved siden af beholderen, og er der ikke plads heri, må lejer
                  selv sørge for fjernelse af yderligere affald. Dette gælder
                  også pap/trækasser og lign. storskrald. Overtrædelse heraf
                  medfører fjernelse for lejers regning.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Støj, Fester m.v.
                <Text fontWeight={'normal'}>
                  Der er mere lydt i en beboelsesejendom, end de fleste er klar
                  over! - Derfor skal der generelt udvises hensyn overfor andre
                  lejere. <br /> <br /> Hvis der holdes fest i lejligheden, skal
                  alle omkringboende lejere orienteres 1 uge i forvejen og efter
                  kl. 23 skal musikstøj dæmpes til et absolut minimum. Ved
                  udluftning under en fest skal musikken stoppes mens vinduerne
                  er åbne.
                </Text>
              </ListItem>
              <ListItem fontWeight={'bold'}>
                Husdyr
                <Text fontWeight={'normal'}>
                  Det er IKKE tillaldt at holde husdyr i vores ejendomme.
                </Text>
              </ListItem>
            </OrderedList>
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenVedligeholdelse} onClose={onCloseVedligeholdelse}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Generel Vedligeholdelse
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Lejer forpligtiger sig til, i henhold til lejekontrakten, at stå for
            den indvendige vedligeholdelse, herunder bl.a. fornyelse af tapet og
            maling, så lejligheden til enhver tid fremstår i god og vedligeholdt
            stand. <br /> <br /> Udlejers forsikring dækker ikke glas og kumme i
            de enkelte lejligheder.
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenAntenne} onClose={onCloseAntenne}>
        <ModalOverlay />
        <ModalContent minW={'50vw'}>
          <ModalHeader>
            <Heading fontSize={['8vw', '1.5vw']} fontWeight={'medium'}>
              Antenne Forhold
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            I de fleste af vore ejendomme, er der lagt kabel ind, så det er
            muligt at købe minimumspakken i disse ejendomme
          </ModalBody>

          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default LejerInfo;
