import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import type { FC } from 'react';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import { CookieConsent } from './components/CookieConsent';
import GeneralLayout from './components/layout/GeneralLayout';
import ScrollToTop from './components/ScrollToTop';
import Ejendom from './pages/Ejendom';
import Ejendomme from './pages/Ejendomme';
import Home from './pages/Home';
import Kontakt from './pages/Kontakt';
import LejerInfo from './pages/LejerInfo';
import NoMatch from './pages/NoMatch';

const breakpoints = createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '48em',
  xl: '48em',
  '2xl': '48em',
});

const theme = extendTheme({
  breakpoints,
  styles: {
    global: () => ({
      p: {
        color: 'black',
      },
    }),
  },
  fonts: {
    heading: 'Playfair Display',
    body: 'Dosis',
  },
  colors: {
    toftejendomme: {
      50: '#f2f0eb',
      100: 'white', //tertiary
      200: '#84B165',
      300: '#78A559',
      400: 'white', //secondary
      500: '#5E8B3F',
      600: '#517E32',
      700: '#457226',
      800: '#386519',
      900: 'white', //primary
    },
  },
});

const App: FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <GeneralLayout>
          <ScrollToTop>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/ejendomme" component={Ejendomme} />
              <Route exact path="/ejendom/:property" component={Ejendom} />
              <Route exact path="/kontakt" component={Kontakt} />
              <Route exact path="/lejerinfo" component={LejerInfo} />
              <Route exact path="*" component={NoMatch} />
            </Switch>
          </ScrollToTop>
        </GeneralLayout>

        <CookieConsent />
      </Router>
    </ChakraProvider>
  );
};

export default App;
