import { Flex, Heading, Icon } from '@chakra-ui/react';
import type { FC } from 'react';
import React from 'react';
import { BiSad } from 'react-icons/bi';
import '../App.scss';

const NoMatch: FC = () => {
  return (
    <>

      <Flex flexDir={'column'} align={'center'} justify={'center'} h={'42vw'}>
          <Icon as={BiSad} w={'10vw'} h={'10vw'} />
        <Heading fontWeight={'medium'} 
          
          fontSize={['8vw', '4vw']}>Siden findes ikke</Heading>
      </Flex>
    </>
  );
};

export default NoMatch;
