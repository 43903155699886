import { Container, Flex } from '@chakra-ui/react';

import Header from '../sections/Header';
import Footer from '../sections/Footer';

const GeneralLayout = (props: any) => {
  return (
    <Container
      pl={[0,'min(50vw, 50px)']}
      pr={[0, 'min(50vw, 50px)']}
      maxW={'1500px'}
      h={'calc(100vh - 40px)'}
    >
      <Header/>
      <Flex direction="column" align={'center'}>
        {props.children}
        <Footer />
      </Flex>
    </Container>
  );
};

export default GeneralLayout;
