import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import type { GridItemProps } from '@chakra-ui/react';
import type { ReactNode } from 'react';

type TestimonialProps = {
  props?: GridItemProps;
  children: React.ReactNode;
};

const Testimonial = ({ props, children }: TestimonialProps) => {
  return <GridItem {...props}>{children}</GridItem>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'lg'}
      h="80%"
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: '""',
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: useColorModeValue('white', 'gray.800'),
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      textAlign={'center'}
      fontSize={'17'}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ name }: { name: string }) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Text fontWeight={600}>{name}</Text>
    </Flex>
  );
};

export default function WithSpeechBubbles() {
  return (
    <Box bg={'#A39E90'} borderRadius={8} mt={{ md: '2vw' }} p={6}>
      <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'} textAlign={'center'}>
          <Heading>Vores tidligere lejere siger</Heading>
        </Stack>
        <Grid
          templateColumns={{ base: 'repeat(1)', md: 'repeat(3, 1fr)' }}
          gap={8}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>5 stjerner</TestimonialHeading>
              <TestimonialText>
                Jeg har som tidligere lejer hos Toft Ejendomme kun haft positive
                oplevelser. Toft Ejendomme er fair, reel og handler lynhurtigt på
                henvendelser og opgaver. Så de bedste anbefalinger og 5 stjerner
                herfra.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Janne Simonsen'} />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Altid hurtig hjælp</TestimonialHeading>
              <TestimonialText>
                Jeg har boet ved Toft Ejendomme i 3 år og har været så glad for
                min lejlighed og bygningen. Der er altid dejlig rent i
                opgangende og hvis man har brug for hjælp er viceværten hurtigt
                til at svare. Kan varmt anbefale Toft Ejendomme.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Gitte Kragelund'} />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>God kommunikation</TestimonialHeading>
              <TestimonialText>
                Jeg har kun haft gode oplevelser med Toft ejendomme. Der har
                altid været et godt samarbejde og god kommunikation samt en tryg
                oplevelse. Jeg anbefaler stærkt Toft Ejendomme.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Cecilie Krabbe'} />
          </Testimonial>
          <Testimonial props={{ colSpan: { base: 1, md: 2 } }}>
            <TestimonialContent>
              <TestimonialHeading>Fantastisk udlejer</TestimonialHeading>
              <TestimonialText>
                Jeg har boet til leje i samme lejlighed hos Toft
                Ejendomme, i næsten 11 år. Jeg flyttede ind i en velrenoveret
                lejlighed, og jeg har været meget tilfreds i alle årene. Jeg har
                altid oplevet en fantastisk udlejer og vicevært. Der har altid
                været en god vedligeholdelse, af ejendommen såvel indendørs som
                udendørs, og har jeg haft brug for hjælp i ejendommen, har jeg
                altid oplevet en fantastisk behandling. Jeg kan kun give Toft
                Ejendomme mine bedste anbefalinger.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Dorte Prang'} />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Central beliggenhed</TestimonialHeading>
              <TestimonialText>
                Vi boede i 4 år i en lejlighed fra Toft Ejendomme på
                Estrupsgade, som vi var utroligt glade for. Lejligheden lå
                centralt, den var af god kvalitet, og alt fungerede som det
                skulle. Skal vi bruge en lejebolig i Silkeborg igen, ved vi
                hvor, vi skal gå hen.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar name={'Mie Jensen'} />
          </Testimonial>
        </Grid>
      </Container>
    </Box>
  );
}
